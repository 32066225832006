import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
   providedIn: 'root'
})
export class ApiService {
   baseUrl = environment.baseUrl;
   constructor(private http: HttpClient) { }

   signup(body: any) {
      return this.http.post(this.baseUrl + `api/auth/register`, body)
   }

   register(body: any) {
      return this.http.post(this.baseUrl + `api/home/update-profile`, body)
   }

   sendOtpRegistration(body: any) {
      return this.http.post(this.baseUrl + `api/auth/send-email-code`, body)
   }

   login(body: any) {
      return this.http.post(this.baseUrl + `api/auth/login`, body)
   }

   logout(body: any) {
      return this.http.post(this.baseUrl + `api/auth/logout`, body)
   }

   validateEmail(body: any) {
      return this.http.post(this.baseUrl + `api/auth/validate-email`, body)
   }

   verifyEmailOtp(body: any) {
      return this.http.post(this.baseUrl + `api/home/verify-email`, body)
   }

   verifyPhoneOtp(body: any) {
      return this.http.post(this.baseUrl + `api/home/verify-phone`, body)
   }

   resendEmailOtp(body: any) {
      return this.http.post(this.baseUrl + `api/home/resend-email-otp`, body)
   }

   resendPhoneOtp(body: any) {
      return this.http.post(this.baseUrl + `api/home/resend-phone-otp`, body)
   }

   forgotPassword(body: any) {
      return this.http.post(this.baseUrl + `api/auth/forgot-password`, body)
   }

   resetPasswordAuth(body: any) {
      return this.http.post(this.baseUrl + `api/auth/reset-password-auth`, body)
   }

   getStates(body: any) {
      return this.http.post(this.baseUrl + `api/get-states`, body)
   }

   getCities(body: any) {
      return this.http.post(this.baseUrl + `api/get-cities`, body)
   }

   addCare(body: any) {
      return this.http.post(this.baseUrl + `api/home/create-recipient`, body)
   }

   upgradeSubscription(body: any) {
      return this.http.post(this.baseUrl + `api/home/upgrade-subscription`, body)

   }

   getRecipientShifts(body: any) {
      return this.http.post(this.baseUrl + `api/home/get-shifts`, body)
   }

   deleteShifts(body: any) {
      return this.http.post(this.baseUrl + `api/home/delete-shift`, body)
   }

   getProviderShifts(body: any) {
      return this.http.post(this.baseUrl + `api/home/get-care-provider-shifts`, body)
   }

   getShiftDetail(body: any) {
      return this.http.post(this.baseUrl + `api/home/shift-details`, body)
   }

   changePassword(body: any) {
      return this.http.post(this.baseUrl + `api/home/change-password`, body)
   }

   getUser(body: any) {
      return this.http.post(this.baseUrl + `api/home/get-user`, body)
   }

   getRecipientsMedication(body: any) {
      return this.http.post(this.baseUrl + `api/home/get-recipient-medication`, body)
   }

   uploadDocument(body: any) {
      return this.http.post(this.baseUrl + `api/home/upload-document`, body)
   }

   getDocument(body: any) {
      return this.http.post(this.baseUrl + 'api/home/get-documents', body)
   }

   deleteDocument(body: any) {
      return this.http.post(this.baseUrl + `api/home/delete-document`, body)
   }

   getCareProvider(body: any) {
      return this.http.post(this.baseUrl + `api/home/get-care-providers`, body)
   }

   deleteProviderRecipient(body: any) {
      return this.http.post(this.baseUrl + `api/home/delete-careprovider`, body)

   }

   getCareRecipient(body: any) {
      return this.http.post(this.baseUrl + `api/home/get-user-care-recipients`, body)
   }

   saveShift(body: any) {
      return this.http.post(this.baseUrl + `api/home/save-shift`, body)
   }

   shiftDetail(body: any) {
      return this.http.post(this.baseUrl + `api/home/shift-details`, body)
   }

   addProviders(body: any) {
      return this.http.post(this.baseUrl + `api/home/add-care-provider`, body)
   }

   editProvider(body: any) {
      return this.http.post(this.baseUrl + `api/home/update-care-provider`, body)

   }

   sendInvite(body: any) {
      return this.http.post(this.baseUrl + `api/home/send-invite`, body)
   }

   detailProvider(body: any) {
      return this.http.post(this.baseUrl + `api/home/get-care-provider`, body)
   }

   deleteProvider(body: any) {
      return this.http.post(this.baseUrl + `api/home/delete-care-provider`, body)

   }

   getCareRecipients(body: any) {
      return this.http.post(this.baseUrl + `api/home/get-care-recipients`, body)
   }

   acceptInvite(body: any) {
      return this.http.post(this.baseUrl + `api/home/accept-invite-request`, body)

   }

   rejectInvite(body: any) {
      return this.http.post(this.baseUrl + `api/home/reject-invite-request`, body)

   }

   deleteRecipient(body: any) {
      return this.http.post(this.baseUrl + `api/home/delete-care-recipient`, body)

   }

   faqDetail(body: any) {
      return this.http.get(this.baseUrl + `api/faq`, body)
   }

   getRecipientDetail(body: any) {
      return this.http.post(this.baseUrl + `api/home/get-user-care-recipient`, body)
   }

   ratingReview(body: any) {
      return this.http.post(this.baseUrl + `api/home/save-rating`, body)
   }

   saveBankDetail(body: any) {
      return this.http.post(this.baseUrl + `api/home/save-bank-detail`, body)
   }

   getBankDetails(body: any) {
      return this.http.post(this.baseUrl + `api/home/get-bank-details`, body)
   }

   deleteBankDetail(body: any) {
      return this.http.post(this.baseUrl + `api/home/delete-bank-detail`, body)

   }

   addNotes(body: any) {
      return this.http.post(this.baseUrl + `api/home/save-note`, body)
   }

   getmynotes(body: any) {
      return this.http.post(this.baseUrl + `api/home/get-user-notes`, body)
   }

   getsharedNotes(body: any) {
      return this.http.post(this.baseUrl + `api/home/get-shared-notes`, body)
   }

   shareNotes(body: any) {
      return this.http.post(this.baseUrl + `api/home/share-note`, body)
   }

   deleteNotes(body: any) {
      return this.http.post(this.baseUrl + `api/home/delete-note`, body)
   }

   getRecipientMedication(body: any) {
      return this.http.post(this.baseUrl + `api/home/get-recipient-medication`, body)
   }

   addRecipientMedication(body: any) {
      return this.http.post(this.baseUrl + `api/home/save-recipient-medication`, body)
   }

   deleteRecipientMedication(body: any) {
      return this.http.post(this.baseUrl + `api/home/delete-medication`, body)
   }

   aboutus() {
      return this.http.get(this.baseUrl + `api/page/about_us`)
   }

   privacy() {
      return this.http.get(this.baseUrl + `api/page/privacy_policy`)
   }

   terms() {
      return this.http.get(this.baseUrl + `api/page/term_conditions`)

      // return this.http.get(this.baseUrl + `api/page/terms-and-conditions`)
   }

   getAppointment(body: any) {
      return this.http.post(this.baseUrl + `api/home/get-appointments`, body)
   }

   addApointment(body: any) {
      return this.http.post(this.baseUrl + `api/home/create-appointment`, body)
   }

   deleteAppointment(body: any) {
      return this.http.post(this.baseUrl + `api/home/delete-appointment`, body)
   }

   deleteAccount(body: any) {
      return this.http.post(this.baseUrl + `api/home/delete-account`, body)
   }

   addTask(body: any) {
      return this.http.post(this.baseUrl + `api/home/save-task`, body)
   }

   getTask(page_no: number, recipient_id?: string) {
      const params = { page_no, recipient_id };

      return this.http.post(this.baseUrl + `api/home/get-tasks`, params);
   }

   taskDetail(params: any) {
      return this.http.post(this.baseUrl + `api/home/get-task`, params);
   }

   deleteTask(body: any) {
      return this.http.post(this.baseUrl + `api/home/delete-task`, body)
   }



}
