import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SignUpComponent } from './components/care-shared auth/sign-up/sign-up.component';
import { LoginComponent } from './components/care-shared auth/login/login.component';
import { ForgotPasswordComponent } from './components/care-shared auth/forgot-password/forgot-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorInterceptor } from './common/interceptor/error-interceptor';
import { HttpInterceptorService } from './common/interceptor/http-interceptor.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { LoaderInterceptor } from './common/interceptor/loader-interceptor';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CommonModule } from '@angular/common';
import { NgxOtpInputModule } from 'ngx-otp-input';
import { YearsPipe } from './common/custompipe/years.pipe';


@NgModule({
  declarations: [
    AppComponent,
    SignUpComponent,
    LoginComponent,
    ForgotPasswordComponent,
 
  ],
  imports: [
    BrowserModule,
    FormsModule,
    NgxOtpInputModule,
    CommonModule,
    ReactiveFormsModule,
    MatSnackBarModule  ,
    NgxSpinnerModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
 



  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
    {provide:HTTP_INTERCEPTORS,useClass:LoaderInterceptor,multi:true},YearsPipe
   ],
  bootstrap: [AppComponent],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }

